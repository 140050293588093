import { template as template_cfdc8a7cffd8486f851bce525e984dc3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cfdc8a7cffd8486f851bce525e984dc3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
