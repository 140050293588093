import { template as template_865c6d2428ea45a8a34e69f5fa3f8705 } from "@ember/template-compiler";
const WelcomeHeader = template_865c6d2428ea45a8a34e69f5fa3f8705(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
