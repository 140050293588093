import { template as template_8d2916468477411b9ce465ab8d00f9ce } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_8d2916468477411b9ce465ab8d00f9ce(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
