import { template as template_9099fbc5ad62414fb2fe0844a183dfc3 } from "@ember/template-compiler";
const FKLabel = template_9099fbc5ad62414fb2fe0844a183dfc3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
